*{scroll-behavior: smooth;}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


h1{
  font-family: 'Amarante', cursive;
  color: #FBCC5C;
  font-size: 8vmin;
  text-shadow: 0px 1px 8px #010A25;
}



.Text-Container{
  width: 50%;
}

.Top-Image{
  background-image: url("./BG1.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: #010A25;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;
}

.Bottom-Image{
  background-image: url("./BG2.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: #EF065A;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;
}

.button {
  margin-left: 4vmin;
  margin-left: 4vmin;
  margin-bottom: 4vmin;
	background-color:#ffffff;
	border-radius:28px;
	display:inline-block;
	cursor:pointer;
	color:#000000;
	font-family:Georgia;
	font-size:17px;
	padding:16px 31px;
	text-decoration:none;
  transition: 0.3s;
}
.button:hover {
	background-color:#dce0ed;
}
.button:active {
	position:relative;
	top:1px;
}

